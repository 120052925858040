//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

am4core.useTheme(am4themes_animated);
export default {
  name: 'flows',
  data() {
    return {
      showChart: true,
      showInnerLoadingChord: false,
      Toggleable_Chord_Chart_Data: [],
      isCall: false,
      showChordSingle: true,
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme'
    })
  },
  methods: {
    ...mapActions({
      requestConversationsTopic: 'requestConversationsTopic',
      requestConversationsFlowcompressed: 'requestConversationsFlowcompressed',
      generateXMLFile: 'generateXMLFile',
    }),
    generateToggleableChordChart() {
      const that = this;
      if (that.chordchartCompressed) {
        that.chordchartCompressed.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const chart = am4core.create(
        that.$refs.toggleableChordDiagram,
        am4charts.ChordDiagram,
      );
      chart.colors.saturation = 0.45;
      chart.colors.step = 3;
      const colors = {
        Rachel: chart.colors.next(),
        Monica: chart.colors.next(),
        Phoebe: chart.colors.next(),
        Ross: chart.colors.next(),
        Joey: chart.colors.next(),
        Chandler: chart.colors.next(),
      };

      chart.data = that.Toggleable_Chord_Chart_Data_Filtered;
      chart.dataFields.fromName = 'from';
      chart.dataFields.toName = 'to';
      chart.dataFields.value = 'value';

      chart.nodePadding = 0.5;
      chart.minNodeSize = 0.01;
      chart.startAngle = 60;
      chart.endAngle = chart.startAngle + 360;
      chart.sortBy = 'value';
      chart.fontSize = 10;
      // chart.radius = am4core.percent(50)
      const nodeTemplate = chart.nodes.template;
      nodeTemplate.readerTitle = 'Click to show/hide or drag to rearrange';
      nodeTemplate.showSystemTooltip = false;
      nodeTemplate.propertyFields.fill = 'color';
      nodeTemplate.tooltipText = "{name}'s Total: {total}";

      // when rolled over the node, make all the links rolled-over
      nodeTemplate.events.on('over', (event) => {
        const node = event.target;
        node.outgoingDataItems.each((dataItem) => {
          if (dataItem.toNode) {
            dataItem.link.isHover = true;
            dataItem.toNode.label.isHover = true;
          }
        });
        node.incomingDataItems.each((dataItem) => {
          if (dataItem.fromNode) {
            dataItem.link.isHover = true;
            dataItem.fromNode.label.isHover = true;
          }
        });

        node.label.isHover = true;
      });

      // when rolled out from the node, make all the links rolled-out
      nodeTemplate.events.on('out', (event) => {
        const node = event.target;
        node.outgoingDataItems.each((dataItem) => {
          if (dataItem.toNode) {
            dataItem.link.isHover = false;
            dataItem.toNode.label.isHover = false;
          }
        });
        node.incomingDataItems.each((dataItem) => {
          if (dataItem.fromNode) {
            dataItem.link.isHover = false;
            dataItem.fromNode.label.isHover = false;
          }
        });

        node.label.isHover = false;
      });

      const { label } = nodeTemplate;
      label.relativeRotation = 90;

      label.fillOpacity = 0.4;
      const labelHS = label.states.create('hover');
      labelHS.properties.fillOpacity = 1;

      nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
      nodeTemplate.adapter.add('fill', (fill, target) => {
        const node = target;
        const counters = {};
        let mainChar = false;
        node.incomingDataItems.each((dataItem) => {
          if (colors[dataItem.toName]) {
            mainChar = true;
          }

          if (isNaN(counters[dataItem.fromName])) {
            counters[dataItem.fromName] = dataItem.value;
          } else {
            counters[dataItem.fromName] += dataItem.value;
          }
        });
        if (mainChar) {
          return fill;
        }

        const count = 0;
        let color;
        let biggest = 0;
        let biggestName;

        for (const name in counters) {
          if (counters[name] > biggest) {
            biggestName = name;
            biggest = counters[name];
          }
        }
        if (colors[biggestName]) {
          fill = colors[biggestName];
        }

        return fill;
      });

      // link template
      const linkTemplate = chart.links.template;
      linkTemplate.strokeOpacity = 0;
      linkTemplate.fillOpacity = 0.15;
      linkTemplate.tooltipText = '{fromName} -> {toName}:{value.value}';

      const hoverState = linkTemplate.states.create('hover');
      hoverState.properties.fillOpacity = 0.7;
      hoverState.properties.strokeOpacity = 0.7;

      that.chordchartCompressed = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `Clienk report Network ${that.currentAIAccount.name} from ${that.getFilterDate.start}  to ${that.getFilterDate.end}`;

    },
    exportSectionToXLS(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updateNetworkData(data);
      }
    },
    updateNetworkData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showInnerLoadingRadar = true;
        that.showInnerLoadingChord = true;
        that.showPagination = false;
        that.isCall = true;
        if (that.chordchartCompressed) {
          that.chordchartCompressed.dispose();
        }
        that.requestConversationsFlowcompressed(data).then((res) => {
            that.Toggleable_Chord_Chart_Data = res.result;
            setTimeout(() => {
              that.showInnerLoadingChord = false;
              that.generateFilteredDataForChord();
            }, 100);
          })
          .catch((err) => {
            console.log('error ', err);
            that.showChart = false;
            that.showInnerLoading = false;
          })
          .finally(() => {
            that.isCall = false;
          });
      }
    },
    generateFilteredDataForChord() {
      const that = this;
      if (that.chordchartCompressed) {
        that.chordchartCompressed.dispose();
      }
      that.showInnerLoadingChord = true;
      const minValue = that.showChordSingle ? 0 : 1;
      const filteredData = _.filter(that.Toggleable_Chord_Chart_Data, (
        obj,
      ) => obj.value > minValue);
      that.Toggleable_Chord_Chart_Data_Filtered = filteredData;
      setTimeout(() => {
        that.showInnerLoadingChord = false;
        that.generateToggleableChordChart();
      }, 100);
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateNetworkData(data);
    },
    isValid(value) {
      if (value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChordSingle() {
      this.generateFilteredDataForChord();
    },
    darkTheme(){
      this.generateToggleableChordChart()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updateNetworkData(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
